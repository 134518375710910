import React from 'react';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {getQueryParam, navigateToParametrized} from '../../../lib/url';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import {GLOBAL_DATA} from '../../../constants/globalData';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchQuotationCopy} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */


class CopyQuotation extends React.PureComponent {

    navigateToQuotationDetail = (quotationId) => {
        const {location} = this.props;
        navigateToParametrized(location, ROUTES.QUOTATION_DETAILS, { [QUERY_PARAMS.ID_QUOTATION]: quotationId });
    };

    render() {
        const {location} = this.props;
        const quotationId = getQueryParam(location, QUERY_PARAMS.ID_QUOTATION);
        return <div>
            <FormModal
                openNode={
                    <Button>
                        <Trans>Kopírovať</Trans>
                    </Button>
                }
                values={{}}
                onFinishSuccessful={this.navigateToQuotationDetail}
                title={<Trans>Kópia cenovej ponuky</Trans>}
                Form={generalNoParameterFormPC(
                    <Trans>Naozaj chcete skopírovať cenovú ponuku?</Trans>,
                    createFetchQuotationCopy(quotationId),
                )}
                Response={null}
                Failed={generalFailedPC(t`Nepodarilo sa skopírovať cenovú ponuku.`)}
            />
        </div>;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.RELOAD_DATA])(CopyQuotation));