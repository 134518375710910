import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import Link from '../../navigation/Link';
import locationHOC from '../../locationProvider/locationHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {RIGHTS} from '../../../constants/Rights';
import {isTradeSell, getBackgroundStyle} from '../../../lib/project';
import {QuotationDetail} from '../../../constants/propTypesDefinitions';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class QuotationDetailsHeader extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.QUOTATION_DETAILS]: QuotationDetail.isRequired,
        location: PropTypes.object.isRequired,
    };

    render() {
        const {location, [GLOBAL_DATA.QUOTATION_DETAILS]: quotationDetails = {}} = this.props;
        return <div>
            <h2 className={'text-center p-3 ' + getBackgroundStyle(quotationDetails.trade)}>
                <RightsWrapper from={RIGHTS.MARKETING}>
                    {
                        isTradeSell(quotationDetails.trade) ?

                            <Trans>Detail odoslanej cenovej ponuky č.</Trans> :
                            <Trans>Detail prijatej cenovej ponuky č.</Trans>
                    }
                </RightsWrapper>
                <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                    {
                        isTradeSell(quotationDetails.trade) ?
                            <Trans>Detail cenovej ponuky č.</Trans> :
                            <Trans>Detail cenovej ponuky č.</Trans>
                    }

                </RightsWrapper>
                {' '}
                {quotationDetails.dealer_reference}
                <RightsWrapper from={RIGHTS.MARKETING}>
                    {' ('}
                    {quotationDetails.status}
                    {')'}
                </RightsWrapper>
            </h2>
            {
                quotationDetails.id_request != null ?
                    <h4 className="text-center py-3">
                        <Trans>Dopyt č.</Trans>
                        {' '}
                        <Link
                            location={location}
                            to={ROUTES.REQUEST_DETAILS}
                            queryParams={{[QUERY_PARAMS.ID_REQUEST]: quotationDetails.id_request}}
                        >
                            {quotationDetails.request_reference}
                        </Link>
                    </h4>
                    : null
            }
        </div>;
    }

}

export default locationHOC(QuotationDetailsHeader);