import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import FormModal from '../../fetch/FormModal';
import DatePicker from '../../general/DatePicker';
import InputTextArea from '../../general/InputTextArea';
import {Button, Form} from 'antd';
import {focus} from '../../../lib/project';
import {formItemLayout, tailFormItemLayout} from '../../../constants/form';
import {createFetchQuotationEdit} from '../../../backend/apiCalls';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {QuotationDetail} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
const FormItem = Form.Item;

/**
 * @fero
 */


class CloseQuotation extends React.PureComponent {
    static propTypes = {
        quotationDetails: QuotationDetail.isRequired,
        onFinishSuccessful: PropTypes.func,
    };

    render() {
        const {quotationDetails = {}, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        return <FormModal
            openNode={
                <Button>
                    <Trans>Ukončenie</Trans>
                </Button>
            }
            values={{quotationDetails}}
            onFinishSuccessful={() => {reload([GLOBAL_DATA.QUOTATION_DETAILS])}}
            title={<Trans>Ukončenie cenovej ponuky</Trans>}
            Form={CloseQuotationFormWrapped}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa nastaviť ukončenie cenovej ponuky.`)}
        />
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(CloseQuotation);

class CloseQuotationForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            quotationDetails: QuotationDetail.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        focus(this.first);
    }

    handleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const expirationNotices = formValues.expiration_notices
                createFetchQuotationEdit()({
                    ...formValues,
                    expiration_notices: expirationNotices != null && expirationNotices !== "" ? expirationNotices : null,
                }).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {onClose, values} = this.props;
        const {quotationDetails} = values;
        const {getFieldDecorator = {}} = this.props.form;
        {getFieldDecorator('id', {initialValue: quotationDetails.id})}
        return <Form>
            <FormItem
                {...formItemLayout}
                label={<Trans>Platí do</Trans>}>
                {getFieldDecorator('active_to', {initialValue: quotationDetails.active_to})(
                    <DatePicker
                        placeholder={t`odvolania`}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Dôvod ukončenia</Trans>}>
                {getFieldDecorator('expiration_notices', {initialValue: quotationDetails.expiration_notices})(
                    <InputTextArea/>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submitDraft" type="primary" loading={loading} onClick={this.handleSubmit}>
                    <Trans>Uložiť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const CloseQuotationFormWrapped = Form.create()(CloseQuotationForm);