import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class QuotationDivisibilitySelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        focus: PropTypes.bool,
    };

    render() {
        const { [GLOBAL_DATA.QUOTATION_DIVISIBILITY_LEVELS]: quotationDivisibilityLevels ,value, ...props } = this.props;
        const valueChecked = value != null ? Number(value) : null;
        return <Select
            {...props}
            options={quotationDivisibilityLevels.map(levels => {
                return {
                    label: levels.name,
                    value: levels.id,
                }
            })}
            value={valueChecked}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.QUOTATION_DIVISIBILITY_LEVELS])(QuotationDivisibilitySelect);