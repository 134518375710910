import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import DatePicker from '../../general/DatePicker';
import InputQuantity from '../../project/InputQuantity';
import {getInputOnChangeEventChecked} from '../../../lib/project';
import {RIGHTS} from '../../../constants/Rights';
import {QuotationDetailLine} from '../../../constants/propTypesDefinitions';
import {Checkbox} from 'antd';

import {Trans, t} from '@lingui/macro';
import QuotationAvailability from '../../project/QuotationAvailability';
import { currentDate } from '../../../lib/date';

/**
 * @fero
 */

class QuotationItemAvailability extends React.PureComponent {
    static propTypes = {
        data: QuotationDetailLine.isRequired,
        onAvailableAtChange: PropTypes.func.isRequired,
        onSupplyDurationChange: PropTypes.func.isRequired,
    };

    render() {
        const {data, onSupplyDurationChange, onAvailableAtChange} = this.props;
        const onStock = (data.supply_duration != null && data.supply_duration == 0);
        return <React.Fragment>
            <RightsWrapper key="customer" to={RIGHTS.DISTRIBUTOR}>
                <QuotationAvailability supplyDuration={data.supply_duration} available_at={data.available_at}/>
            </RightsWrapper>
            <RightsWrapper key="dealer" from={RIGHTS.MARKETING}>
                <div className="d-flex align-items-center p-1">
                    <Checkbox
                        className="text-nowrap"
                        checked={onStock}
                        onChange={(e) => {
                            const selected = getInputOnChangeEventChecked(e);
                            onSupplyDurationChange(selected ? 0 : null);
                        }}
                    >
                        <Trans>skladom</Trans>
                    </Checkbox>
                </div>
                <div className="d-flex align-items-center p-1">
                <span className="mr-2">
                        <Trans>na obj.:</Trans>
                    </span>
                    <InputQuantity
                        size="small"
                        className="qutation-details-table-supply-duration text-right"
                        value={onStock ? null : data.supply_duration}
                        disabled={onStock}
                        onChange={onSupplyDurationChange}
                        unit={
                            <span className="text-nowrap">
                                <Trans>prac. dní</Trans>
                            </span>
                        }
                    />
                </div>
                <div className="d-flex align-items-center p-1">
                    <span className="mr-2">
                        <Trans>od:</Trans>
                    </span>
                    <DatePicker
                        size="small"
                        className="qutation-details-table-available-at-input"
                        value={data.available_at}
                        disabled={onStock}
                        min={currentDate()}
                        onChange={onAvailableAtChange}
                    />
                </div>
            </RightsWrapper>
        </React.Fragment>;
    }

}

export default QuotationItemAvailability;