export function setSelected(isSelected, itemId, selectedItemsIds) {
    
    if (isSelected) 
    {
        if(selectedItemsIds == null)
            return [itemId];
        else if(selectedItemsIds.includes(itemId))
            return selectedItemsIds;
        else
            return [...selectedItemsIds, itemId];
    } 
    else 
    {
        if(selectedItemsIds == null)
        {
            // this should not happen
            console.warn('selection checkbox bug');
            return [];
        }
        else if(selectedItemsIds.length == 1 && selectedItemsIds[0] == itemId)
        {
            // last one enables all
            return null;
        }
        else
        {
            // standard behavior
            return selectedItemsIds.filter(id => id != itemId);
        }
    }
};

export function isSelected(itemId, selectedItemIds) {
    if(selectedItemIds == null)
        return false; // do not show selected checkbox
    else
        return selectedItemIds.includes(itemId);
}