import React from 'react';
import PropTypes from 'prop-types';
import RemoveQuotation from '../quotations/RemoveQuotation';
import CloseQuotation from './CloseQuotation';
import CopyQuotation from './CopyQuotation';
import SendQuotation from './SendQuotation';
import SaveQuotation from './SaveQuotation';
import MakeOrder from './MakeOrder';
import MakeQuotationFromIncoming from './MakeQuotationFromIncoming';
import DealModal from '../../project/DealModal';
import HistoryModal from '../../history/HistoryModal';
import locationHOC from '../../locationProvider/locationHOC';
import {RIGHTS} from '../../../constants/Rights';
import {isTradeSell} from '../../../lib/project';
import {navigateToParametrized} from '../../../lib/url';
import {QuotationDetail, QuotationOrderItems} from '../../../constants/propTypesDefinitions';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {Trans, t} from '@lingui/macro';
import CopyQuotationToCart from './CopyQuotationToCart';
import PdfLink from '../../project/PdfLink';
import MailPreview from '../../project/MailPreview';
import PageActionsLayout from '../../general/PageActionsLayout';
import NavigateButton from '../../general/NavigateButton';
import ReopenQuotation from './ReopenQuotation';
import FitQuotationPrices from './FitQuotationPrices';

/**
 * @fero
 */

class QuotationDetailsActions extends React.PureComponent {
    static propTypes = {
        quotationDetails: QuotationDetail.isRequired,
        divisibleOrderItems: QuotationOrderItems,
        location: PropTypes.object.isRequired,
    };

    render() {
        const {location, quotationDetails, divisibleOrderItems} = this.props;
        const quotationId = quotationDetails.id;
        const isSelling = isTradeSell(quotationDetails.trade);
        return <PageActionsLayout
            back={{
                to: ROUTES.QUOTATIONS,
                title: <Trans>Cenové ponuky</Trans>
            }}
            actions={[
                {
                    rightsFrom: RIGHTS.DEALER,
                    node: <HistoryModal
                        objectId={quotationId}
                        objectName={quotationDetails.dealer_reference}
                        objectType="quotation"
                    />
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: isSelling ? <MailPreview 
                        objectType="quotation" 
                        objectId={quotationId}
                    /> : null
                },
                {
                    node: isSelling ? <PdfLink 
                        objectType="quotation" 
                        objectId={quotationId}
                    /> : null
                },
                {
                    node: <DealModal
                        dealPartId={quotationId}
                        dealId={quotationDetails.id_deal}
                    />
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: <CopyQuotationToCart 
                        quotationDetails={quotationDetails}
                        divisibleOrderItems={divisibleOrderItems}
                    />
                },
                {
                    rightsFrom: RIGHTS.DEALER,
                    node: <ReopenQuotation/>
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: <CopyQuotation/>
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: <FitQuotationPrices
                        quotationDetails={quotationDetails}
                    />
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: isSelling ? <SendQuotation/> : <SaveQuotation/>
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: !isSelling ? <MakeQuotationFromIncoming
                        quotationDetails={quotationDetails}
                        onFinishSuccessful={(result) => {
                            navigateToParametrized(location, ROUTES.QUOTATION_DETAILS, {[QUERY_PARAMS.ID_QUOTATION]: result});
                        }}
                    /> : null
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: <CloseQuotation 
                        quotationDetails={quotationDetails}
                    />
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: <RemoveQuotation
                        quotation={quotationDetails}
                        onFinishSuccessful={() => {
                            navigateToParametrized(location, ROUTES.QUOTATIONS, {});
                        }}
                        buttonContent={<Trans>Odstrániť</Trans>}
                    />
                },
                {
                    node: <MakeOrder 
                        divisibleOrderItems={divisibleOrderItems}
                    />
                },
            ]}      
        />;
    }

}

export default locationHOC(QuotationDetailsActions);