import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchQuotationReopen} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import { QuotationDetail } from '../../../constants/propTypesDefinitions';
import { isQuotationSend } from '../../../lib/project';

/**
 * @fero
 */


class ReopenQuotation extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.QUOTATION_DETAILS]: QuotationDetail.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    reloadData = () => {
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.QUOTATION_DETAILS]);
    };

    render() {
        const {[GLOBAL_DATA.QUOTATION_DETAILS]: quotationDetails} = this.props;
        const disabled = !isQuotationSend(quotationDetails);
        return <FormModal
            disabled={disabled}
            openNode={
                <Button icon="edit" disabled={disabled}>
                    <span className="ml-2">
                        <Trans>Upraviť</Trans>
                    </span>
                </Button>
            }
            values={{}}
            onFinishSuccessful={this.reloadData}
            title={<Trans>Editácia odoslanej cenovej ponuky</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete povoliť editáciu odoslanej cenovej ponuky?</Trans>,
                createFetchQuotationReopen(quotationDetails.id)
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa znovu otvoriť cenovú ponuku.`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.QUOTATION_DETAILS, GLOBAL_DATA.RELOAD_DATA])(ReopenQuotation);