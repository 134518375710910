import React from 'react';
import PropTypes from 'prop-types';
import {QuotationDetail} from '../../../constants/propTypesDefinitions';
import { Button, Form, Radio } from 'antd';
import FormModal from '../../fetch/FormModal';
import {Trans, t} from '@lingui/macro';
import generalFailedPC from '../../fetch/generalFailedPC';
import { formItemLayout, tailFormItemLayout } from '../../../constants/form';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import Tooltip from '../../general/Tooltip';
import CurrencySelect from '../../project/CurrencySelect';
import InputNumber from '../../general/InputNumber';
const FormItem = Form.Item;

/**
 * @dusan
 */

class QuotationCurrency extends React.PureComponent {
    static propTypes = {
        quotationDetails: QuotationDetail.isRequired,
        editQuotation: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    render() {
        const {quotationDetails, editQuotation, disabled, [SESSION_ATTRIBUTES.SETTINGS]: settings} = this.props;
        return <div className="d-flex align-items-center">
            {quotationDetails.id_currency}
            { quotationDetails.id_currency != settings.default_currency ? 
                <span className="ml-2 text-nowrap">
                    {`1 ${settings.default_currency} = ${quotationDetails.exchange_rate} ${quotationDetails.id_currency}`}
                </span>
                : null
            }
            <FormModal
                disabled={disabled}
                openNode={
                    <Tooltip title={<Trans>upraviť menu alebo kurz</Trans>}>
                        <Button size="small" icon="edit" disabled={disabled} className="ml-2"/>
                    </Tooltip>
                }
                values={{quotationDetails, editQuotation, defaultCurrency: settings.default_currency}}
                title={<Trans>Mena a kurz</Trans>}
                Form={EditCurrencyFormWrapped}
                Response={null}
                Failed={generalFailedPC(t`Nepodarilo upraviť menu alebo kurz.`)}
            />
        </div>;
    }
}

export default withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(QuotationCurrency);

class EditCurrencyForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        values: PropTypes.shape({
            quotationDetails: QuotationDetail.isRequired,
            editQuotation: PropTypes.func.isRequired,
            defaultCurrency: PropTypes.string.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectedCurrency: props.values != null && props.values.quotationDetails != null ? props.values.quotationDetails.id_currency : null,
        };
    }


    handleSubmit = (e) => {
        const {values, onClose} = this.props;
        const {editQuotation} = values;
        
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err && editQuotation != null) 
            {
                this.setState({loading: true});
                editQuotation(formValues);
                this.setState({loading: false});
                onClose();
            }
        });
    };

    onCurrencyChange = (newCurrency) => {
        const {setFieldsValue} = this.props.form;
        setFieldsValue({exchange_rate: null});
        this.setState({selectedCurrency: newCurrency});
    }

    render() {
        const {values, onClose} = this.props;
        const {quotationDetails, defaultCurrency} = values;
        const {getFieldDecorator} = this.props.form;
        const {loading, selectedCurrency} = this.state;

        return <Form onSubmit={this.handleSubmit}>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Mena</Trans>}
            >
                {getFieldDecorator('id_currency', {
                    initialValue: quotationDetails.id_currency ?? defaultCurrency,
                    onChange: this.onCurrencyChange
                })(
                    <CurrencySelect/>
                )}
            </FormItem>
            { selectedCurrency != defaultCurrency ?
                <FormItem 
                    {...formItemLayout}
                    label={<Trans>Kurz</Trans>}
                >
                    {getFieldDecorator('exchange_rate', {initialValue: selectedCurrency == quotationDetails.id_currency ? quotationDetails.exchange_rate : null})(
                        <InputNumber
                            className="text-right"
                            allowClear={true}
                            placeholder={t`podľa ECB`}
                            after={<span className="ml-2 text-nowrap">{`${selectedCurrency} = 1 ${defaultCurrency}`}</span>}
                        />
                    )}
                </FormItem>
                : null
            }
            <FormItem 
                {...formItemLayout}
                label={<Trans>Prepočítať ceny</Trans>}
            >
                {getFieldDecorator('refresh_prices', {initialValue: 1})(
                    <Radio.Group>
                        <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit} loading={loading}>
                    <Trans>Uložiť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const EditCurrencyFormWrapped = Form.create()(EditCurrencyForm);