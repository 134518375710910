import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {QuotationDetail} from '../../../constants/propTypesDefinitions';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchQuotationSave} from '../../../backend/apiCalls';
import {isQuotationSend, areAllProductsBind} from '../../../lib/project';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */


class SaveQuotation extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        [GLOBAL_DATA.QUOTATION_DETAILS]: QuotationDetail.isRequired,
    };

    reloadQuotation = () => {
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.QUOTATION_DETAILS]);
    };

    getResponse = () => {
        return <div>
            <h4>{t`Cenová ponuka úspešne uložená`}</h4>
        </div>;
    };

    render() {
        const {[GLOBAL_DATA.QUOTATION_DETAILS]: quotationDetails = {}} = this.props;
        const disabled = isQuotationSend(quotationDetails) || !areAllProductsBind(quotationDetails);
        return <div>
            <FormModal
                disabled={disabled}
                openNode={
                    <Button 
                        type="primary"
                        disabled={disabled}
                    >
                        <Trans>Uložiť</Trans>
                    </Button>
                }
                values={{}}
                onFinishSuccessful={this.reloadQuotation}
                title={<Trans>Uloženie cenovej ponuky</Trans>}
                Form={generalNoParameterFormPC(
                    <Trans>Naozaj chcete aktivovať cenovú ponuku?</Trans>,
                    createFetchQuotationSave(quotationDetails.id),
                )}
                Response={this.getResponse}
                Failed={generalFailedPC(t`Nepodarilo sa uložiť cenovú ponuku.`)}
            />
        </div>;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA, GLOBAL_DATA.QUOTATION_DETAILS])(SaveQuotation);