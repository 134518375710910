import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import FormModal from '../../fetch/FormModal';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {QuotationDetail} from '../../../constants/propTypesDefinitions';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchQuotationSend} from '../../../backend/apiCalls';
import {isQuotationSend, areAllProductsBind, isQuotationActive, isTradeSell} from '../../../lib/project';
import {Button, Form, Radio} from 'antd';
import {Trans, t} from '@lingui/macro';
import { formItemLayout, tailFormItemLayout } from '../../../constants/form';
const FormItem = Form.Item;

/**
 * @fero
 */


class SendQuotation extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        [GLOBAL_DATA.QUOTATION_DETAILS]: QuotationDetail.isRequired,
    };

    reloadQuotation = () => {
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.QUOTATION_DETAILS]);
    };

    render() {
        const {[GLOBAL_DATA.QUOTATION_DETAILS]: quotationDetails = {}} = this.props;
        const disabled = !areAllProductsBind(quotationDetails) || !isQuotationActive(quotationDetails);
        return <div>
            <FormModal
                disabled={disabled}
                openNode={
                    <Button 
                        disabled={disabled}
                        type={isQuotationSend(quotationDetails) ? "" : "primary"}
                    >
                        <Trans>Odoslať zákazníkovi</Trans>
                    </Button>
                }
                values={{quotationDetails}}
                onFinishSuccessful={this.reloadQuotation}
                title={<Trans>Odoslanie cenovej ponuky</Trans>}
                Form={SendQuotationFormWrapped}
                Response={SendQuotationResponse}
                Failed={generalFailedPC(t`Nepodarilo sa odoslať cenovú ponuku.`)}
            />
        </div>;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA, GLOBAL_DATA.QUOTATION_DETAILS])(SendQuotation);

class SendQuotationResponse extends React.PureComponent {
    render() {
        const {fetchResult, values} = this.props;
        const {quotationDetails = {}} = values;
        return isQuotationSend(quotationDetails) ?
            t`Cenová ponuka bola úspešne odoslaná`
            :
            <div>
                <h4>{t`Cenová ponuka bola úspešne aktivovaná`}</h4>
                { fetchResult ?
                    <span>{t`Cenová ponuka bola zaslaná na e-mail.`}</span>
                    :
                    <span className="color-red">{t`Cenová ponuka NEBOLA odoslaná.`}</span>
                }
            </div>;
    }
}

class SendQuotationForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            quotationDetails: QuotationDetail.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    handleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                createFetchQuotationSend()(formValues).then((response) => {
                    this.setState({loading: false});
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {onClose, values} = this.props;
        const {quotationDetails = {}} = values;
        const {loading} = this.state;
        const {getFieldDecorator} = this.props.form;
        const isSent = isQuotationSend(quotationDetails);
        const customerChecked = quotationDetails.customer != null ? quotationDetails.customer : {};
        
        {getFieldDecorator('id', {initialValue: quotationDetails.id})}
        return <Form onSubmit={this.handleSubmit}>
            <h5 className="mb-3">     
                <Trans>Naozaj chcete záväzne odoslať cenovú ponuku?</Trans>
                {customerChecked.id_channel == null ?
                    <div className="p-2 d-flex color-red">
                        <Trans>VAROVANIE:</Trans>
                        <div className="pl-2">
                            <Trans>Pre danú organizáciu nie je nastavený komunikačný kanál.</Trans>
                        </div>
                    </div>:
                    null
                }
                { quotationDetails.user == null ?
                    <div className="p-2 d-flex color-red">
                        <Trans>VAROVANIE:</Trans>
                        <div className="pl-2">
                            <Trans>Nie je nastavená kontakná osoba.</Trans>
                        </div>
                    </div>:
                    null
                }
            </h5>
            { !isSent ? 
                <FormItem 
                    {...formItemLayout}
                    label={<Trans>Odoslať zákazníkovi</Trans>}
                >
                    {getFieldDecorator('no_message', {initialValue: 0})(
                        <Radio.Group>
                            <Radio.Button value={0}><Trans>Áno</Trans></Radio.Button>
                            <Radio.Button value={1}><Trans>Nie</Trans></Radio.Button>
                        </Radio.Group>
                    )}
                </FormItem>
                : null
            }
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit} loading={loading}>
                    <Trans>Odoslať</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const SendQuotationFormWrapped = Form.create()(SendQuotationForm);