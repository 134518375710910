import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import withDataHOC from '../../dataProvider/withDataHOC';
import ProductModalTable from '../../project/ProductModalTable';
import AddProductToQuotation from './AddProductToQuotation';
import {isQuotationSend} from '../../../lib/project';
import {QuotationDetail} from '../../../constants/propTypesDefinitions';
import {RIGHTS} from '../../../constants/Rights';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import EditActions from '../../products/table/EditActions';
import Tooltip from '../../general/Tooltip';

/**
 * @fero
 */

class QuotationAddProduct extends React.PureComponent {
    static propTypes = {
        quotationDetails: QuotationDetail.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        className: PropTypes.string,
        label: PropTypes.node,
    };

    render() {
        const {quotationDetails, [GLOBAL_DATA.RELOAD_DATA]: reload, className, label} = this.props;
        const {customer = {}} = quotationDetails;
        return <RightsWrapper from={RIGHTS.MARKETING}>
            <ProductModalTable
                className={className}
                customerId={customer.id}
                hideGeneric={true}
                quotationId={quotationDetails.id}
                modalTitle={<span><Trans>Pridanie produktov do cenovej ponuky</Trans></span>}
                MainRowActions={AddProductToQuotation}
                InfoRowActions={EditActions}
                onClose={() => {
                    reload([GLOBAL_DATA.QUOTATION_DETAILS]);
                }}
                openNode={
                    <Tooltip title={<Trans>Pridať riadky do cenovej ponuky</Trans>}>
                        <Button
                            disabled={isQuotationSend(quotationDetails)}
                            size="small"
                            icon="plus"
                            type="primary"
                        >
                            {label}
                        </Button>
                    </Tooltip>
                }
            />
        </RightsWrapper>
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(QuotationAddProduct);