import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchQuotationRemoveItem} from '../../../backend/apiCalls';
import {QuotationDetailLine} from '../../../constants/propTypesDefinitions';
import Tooltip from '../../general/Tooltip';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */


class RemoveQuotationItem extends React.PureComponent {
    static propTypes = {
        data: QuotationDetailLine.isRequired,
        buttonClassName: PropTypes.string,
    };

    reloadData = () => {
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.QUOTATION_DETAILS]);
    };

    render() {
        const {data, buttonClassName} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Odstrániť položku</Trans>}>
                    <Button className={buttonClassName ? buttonClassName : ''} size="small" type="danger" icon="close"/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={this.reloadData}
            title={<Trans>Odstránenie položky</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete odstrániť položku z cenovej ponuky?</Trans>,
                () => {
                    return createFetchQuotationRemoveItem()({
                        id_quotation: data.id_quotation,
                        id_item: data.id_item
                    })
                },
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť položku z cenovej ponuky.`)}
        />
            ;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(RemoveQuotationItem);