import React from 'react';
import PropTypes from 'prop-types';
import ButtonFetch from '../../fetch/ButtonFetch';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchQuotationAddItem} from '../../../backend/apiCalls';
import {Trans, t} from '@lingui/macro';
import InputNumber from '../../general/InputNumber';
import { Product } from '../../../constants/propTypesDefinitions';

/**
 * @fero
 */


class AddProductToQuotation extends React.PureComponent {
    static propTypes = {
        quotationId: PropTypes.number.isRequired,
        numberOfItems: PropTypes.number.isRequired,
        onChangeNumberOfItems: PropTypes.func.isRequired,
        product: Product.isRequired,
    };

    onInputEnter = (event) => {
        if (
            this.addButton != null &&
            this.addButton.handleSubmit != null &&
            event != null &&
            event.keyCode == 13
        ) {
            this.addButton.handleSubmit();
        }
    };

    render() {
        const {onChangeNumberOfItems, numberOfItems, product, quotationId} = this.props;
        return <div className="d-flex justify-content-center">
            <InputNumber
                className="text-right"
                size={'small'}
                onChange={onChangeNumberOfItems}
                value={numberOfItems}
                min={Number(product.quantity_min)}
                step={Number(product.quantity_div)}
                onKeyUp={this.onInputEnter}
            />
            <ButtonFetch
                ref={node => this.addButton = node}
                size={'small'}
                type="primary"
                icon="plus"
                values={{
                    products: [{
                        designation: product.designation, 
                        numberOfItems: numberOfItems,
                        quantityUnits: product.quantity_units
                    }]
                }}
                Response={AddProductToQuotationResponse}
                Failed={generalFailedPC(t`Pridanie do cenovej ponuky zlyhalo`)}
                fetchFunction={createFetchQuotationAddItem({
                    id_quotation: quotationId,
                    id_product: product.id,
                    quantity: numberOfItems,
                })}
            />
        </div>;
    }

}


class AddProductToQuotationResponse extends React.PureComponent {
    static propTypes = {
        values: PropTypes.shape({
                products: PropTypes.arrayOf(
                    PropTypes.shape({
                            designation: PropTypes.string.isRequired,
                            numberOfItems: PropTypes.number.isRequired,
                            quantityUnits: PropTypes.string.isRequired,
                        }
                    ).isRequired
                ).isRequired
            }
        ).isRequired,
    };

    render() {
        const {values} = this.props;
        return <div>
            <h4>{t`Pridané do cenovej ponuky`}</h4>
            {values.products.map((item, index) => {
                return <div key={index}>
                    {`${item.designation} : ${item.numberOfItems} ${item.quantityUnits}`}
                </div>
            })}
            <div>

            </div>
        </div>;
    }

}

export default AddProductToQuotation;