import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {isQuotationSend} from '../../../lib/project';
import NavigateButton from '../../general/NavigateButton';
import Tooltip from '../../general/Tooltip';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import {QuotationDetail, QuotationOrderItems} from '../../../constants/propTypesDefinitions';
import { currentDate, dateDiff } from '../../../lib/date';

/**
 * @fero
 */


class MakeOrder extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        divisibleOrderItems: QuotationOrderItems,
        [GLOBAL_DATA.QUOTATION_DETAILS]: QuotationDetail.isRequired,
    };

    render() {
        const {divisibleOrderItems} = this.props;
        const details = this.props[GLOBAL_DATA.QUOTATION_DETAILS];
        const quotationId = details.id;
        const customerChecked = details.customer != null ? details.customer : {};
        const userChecked = details.user != null ? details.user : {};
        if(details != null && customerChecked.id != null &&
            (details.active_to == null || dateDiff(details.active_to, currentDate()) >= 0)) {
            return <NavigateButton
                type="primary"
                to={ROUTES.QUOTATION_MAKE_ORDER}
                disabled={!isQuotationSend(details)}
                queryParams={{
                    [QUERY_PARAMS.ID_USER]: userChecked.id,
                    [QUERY_PARAMS.QUOTATION_ORDER_ID_CUSTOMER]: customerChecked.id,
                    [QUERY_PARAMS.ID_QUOTATION]: quotationId,
                    [QUERY_PARAMS.QUOTATION_ORDER_ITEMS]: divisibleOrderItems != null ? JSON.stringify(divisibleOrderItems) : null,
                }}
            >
                <Trans>Objednať</Trans>
            </NavigateButton>;
        }
        else
        {
            return <Tooltip stopPropagationOnClick={true} title={<Trans>Platnosť ponuky vypršala</Trans>}>
                <Button
                    type="primary"
                    disabled={true}
                >
                    <Trans>Objednať</Trans>
                </Button>
            </Tooltip>;
        }
}

}

export default withDataHOC([GLOBAL_DATA.QUOTATION_DETAILS])(MakeOrder);