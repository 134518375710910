import React from 'react';
import Helmet from '../project/Helmet';
import QuotationDetailsActions from './quotationDetails/QuotationDetailsActions';
import QuotationDetailsHeader from './quotationDetails/QuotationDetailsHeader';
import QuotationAddProduct from './quotationDetails/QuotationAddProduct';
import withDataHOC from '../dataProvider/withDataHOC';
import QuotationDetailsTable from './quotationDetails/QuotationDetailsTable';
import QuotationDetailsInfo from './quotationDetails/QuotationDetailsInfo';
import AttachmentsForDealerAndUser from '../project/AttachmentsForDealerAndUser';
import {QuotationDetail} from '../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Trans, t} from '@lingui/macro';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';

/**
 * @fero
 */

class QuotationDetailsPage extends React.Component {
    static propTypes = {
        [GLOBAL_DATA.QUOTATION_DETAILS]: QuotationDetail.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedItemsIds: null,
            overrideQuantities: {},
        };
    }

    setSelected = (ids) => {
        this.setState({selectedItemsIds: ids});
    };

    setQuantity = (itemId, quantity, quantityPerPackage) => {  
        let quantities = this.state.overrideQuantities;
        quantities[itemId] = (quantity ? (Number(quantity) * Number(quantityPerPackage || 1)) : null);
        this.setState({overrideQuantities: quantities});
    };

    resetQuantity = () => {
        this.setState({overrideQuantities: {}});
    };

    getDivisibleOrderItemsArray = () => {
        const {[GLOBAL_DATA.QUOTATION_DETAILS]: quotationDetails} = this.props;
        const {selectedItemsIds, overrideQuantities} = this.state;

        if(selectedItemsIds == null)
            return {}; // all

        const lines = quotationDetails != null && Array.isArray(quotationDetails.lines) ? quotationDetails.lines : [];
        
        return selectedItemsIds.map(itemId => {
            const line = lines.find(obj => obj.id_item == itemId) || {};
            return {
                id_item: itemId,
                quantity: overrideQuantities[itemId] || line.quantity,
            }
        });
    };

    render() {
        const {
            [GLOBAL_DATA.QUOTATION_DETAILS]: quotationDetails = {},
            [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile
        } = this.props;
        const {selectedItemsIds, overrideQuantities} = this.state;
        const userChecked = quotationDetails.user != null ? quotationDetails.user : {};
        return <React.Fragment>
            <Helmet
                title={`${t`Cenová ponuka`} ${quotationDetails.dealer_reference}`}
            />
            <div className="px-3 full-size-height full-size-width overflow-y-auto">
                <QuotationDetailsActions
                    quotationDetails={quotationDetails}
                    divisibleOrderItems={this.getDivisibleOrderItemsArray()}
                />
                <QuotationDetailsHeader quotationDetails={quotationDetails}/>
                <QuotationDetailsInfo quotationDetails={quotationDetails}/>
                { isMobile ? 
                    <QuotationAddProduct 
                        quotationDetails={quotationDetails}
                        className="py-2"
                        label={<Trans>Pridať produkt</Trans>}
                    />
                    :
                    null
                }
                <QuotationDetailsTable
                    quotationDetails={quotationDetails}
                    selectedItemsIds={selectedItemsIds}
                    overrideQuantities={overrideQuantities || {}}
                    setSelected={this.setSelected}
                    setQuantity={this.setQuantity}
                    resetQuantity={this.resetQuantity}
                />
                <AttachmentsForDealerAndUser
                    quotationId={quotationDetails.id}
                    userId={userChecked.id}
                />
            </div>
        </React.Fragment>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE])(
    withDataHOC([GLOBAL_DATA.QUOTATION_DETAILS])(QuotationDetailsPage)
);